<template>
  <VRow class="fill-height" align="stretch">
    <VCol cols="12">
      <VCard flat class="fill-height" color="cardPrimary">
        <KioskInstallForm @submitted="onInstallFormSubmitted" />
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import KioskInstallForm from './components/KioskInstallForm'
import * as KioskGuards from '@/guards'

export default {
  name: 'InstallationIndex',
  components: {
    KioskInstallForm,
    ...KioskGuards
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const isInstalled = vm.$store.getters['kiosk/isInstalled']
      if (isInstalled) {
        vm.$router.push(vm.redirectWhenInstalled)
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'install.settings') {
      this.$store.commit('kiosk/SET_PERMISSIONS', [])
    }

    next()
  },
  props: {
    redirectOnSubmitted: {
      type: Object,
      default() {
        return { name: 'install.settings' }
      }
    },
    redirectWhenInstalled: {
      type: Object,
      default() {
        return { name: 'dial.index' }
      }
    }
  },
  methods: {
    onInstallFormSubmitted(submitOk) {
      if (submitOk) {
        this.$store.commit('kiosk/SET_PERMISSIONS', ['install:*'])
        this.$router.push(this.redirectOnSubmitted)
      }
    }
  }
}
</script>
