<template>
  <VDialog v-model="show" width="550" v-bind="dialogProps" v-on="$listeners">
    <slot v-bind="actions" />
  </VDialog>
</template>

<script>
export default {
  name: 'KioskDialogConfirm',
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default() {
        return {
          show: false,
          message: null,
          timeout: 3000,
          text: '',
          type: 'default'
        }
      }
    },
    confirm: {
      type: Function,
      default() {}
    },
    cancel: {
      type: Function,
      default() {}
    },
    close: {
      type: Function,
      default() {
        return false
      }
    },
    immediate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: this.value.show || false
    }
  },
  computed: {
    dialogProps() {
      const { show, ...props } = this.value
      return { ...this.$attrs, ...props }
    },
    actions() {
      return {
        close: this.$close,
        confirm: this.$confirm,
        cancel: this.$cancel
      }
    }
  },
  watch: {
    value({ show }) {
      this.show = show
    },
    show() {
      if (!this.show) {
        this.$emit('close')
        return
      }
      this.$emit('open')
    }
  },
  mounted() {
    if (this.immediate) {
      this.show = true
    }
  },
  methods: {
    async $close() {
      this.show = await this.close(this.dialogProps)
    },
    async $cancel() {
      await this.cancel(this.dialogProps)
      this.$close()
    },
    async $confirm() {
      await this.confirm(this.dialogProps)
      this.$close()
    }
  }
}
</script>
<style scope>
.divider {
  position: relative;
  width: 3px;
  left: 0px;
  height: 35px;
  background-color: var(--v-error-base) !important;
  border-color: var(--v-error-base) !important;
}
</style>
