<template>
  <VContainer class="fill-height">
    <VRow class="fill-height" align="center" justify="center" pa-5>
      <KioskForm
        v-if="form"
        v-model="form"
        name="app.kiosk.install"
        style="width:80vw"
      >
        <template #default>
          <slot name="title">
            <VRow>
              <VCardText
                v-t="'app.kiosk.forms.install.title'"
                class="display-1 font-weight-light"
              />
            </VRow>
          </slot>
          <VRow>
            <VCol cols="9" class="mb-4" style="height:65px">
              <KioskInstallFormNotifications :form="form" />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="9">
              <KioskFormField v-model="form.fields.domain" solo filled />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="9">
              <KioskFormField
                v-model="form.fields.installationCode"
                solo
                filled
              />
            </VCol>
            <VCol cols="3" ml-5>
              <VBtn
                data-heap-id="field_submit"
                large
                type="submit"
                color="primary"
                :loading="form.state.loading"
                :disabled="form.$v.$invalid"
                class="v-btn-custom"
              >
                <span v-text="form.$t('login.btn.text')" />
                <VIcon right v-text="'arrow_forward'" />
              </VBtn>
            </VCol>
          </VRow>
        </template>
      </KioskForm>
    </VRow>
  </VContainer>
</template>

<script>
import { required, minLength, baseURL } from '@/validators'
import { FormBuilder } from '@/lib/kiosk'
import KioskForm from '@/components/KioskForm'
import KioskFormField from '@/components/KioskFormField'
import KioskInstallFormNotifications from './KioskInstallFormNotifications'

export default {
  name: 'KioskInstallForm',
  components: {
    KioskForm,
    KioskFormField,
    KioskInstallFormNotifications
  },
  props: {
    formData: {
      type: Function,
      default() {
        const { domain } = this.$store.state['kiosk'] || {}
        return { domain, installationCode: '' }
      }
    },
    installHandler: {
      type: Function,
      async default(formState) {
        await this.$store.dispatch('kiosk/install', formState)
      }
    }
  },
  data() {
    return {
      form: null,
      fields: this.formData()
    }
  },
  mounted() {
    this.form = FormBuilder.fromComponent(this)
      .setFields((as) => ({
        domain: as.component('VTextField'),
        installationCode: as.component('VTextField')
      }))
      .setSubmitHandler((formState) => this.installHandler(formState))
      .build('install')
  },

  validations() {
    return {
      fields: {
        domain: {
          required,
          baseURL
        },
        installationCode: {
          required,
          minLength: minLength(3)
        }
      }
    }
  }
}
</script>
