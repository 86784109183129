<template>
  <KioskFormNotifications :form="form">
    <template #[errorCodes.KIOSK_INSTALLATION_BRV_000006]="{errorCode}">
      <KioskDialogConfirm immediate>
        <template #default="{ close }">
          <VCard tile>
            <VCardTitle class="pl-0">
              <VIcon
                class="ml-3"
                :color="'error'"
                size="30"
                v-text="'error_outline'"
              />
              <h3
                class="headline font-weight-medium ml-3 error--text"
                v-text="form.$t(`errors.${errorCode}.dialog.title`)"
              />
              <VSpacer />
              <VBtn icon text @click="close"> <VIcon v-text="'close'" /> </VBtn>
            </VCardTitle>
            <VCardText class="pa-4">
              <p
                class="subheading"
                v-text="form.$t(`errors.${errorCode}.dialog.text`)"
              />
            </VCardText>
            <slot name="shiftInfos" />
            <VCardActions class="pa-3">
              <VSpacer />
              <VBtn
                color="primary"
                large
                @click="close"
                v-text="$t('app.kiosk.ok.btn.text')"
              />
            </VCardActions>
          </VCard>
        </template>
      </KioskDialogConfirm>
    </template>
    <template #default="{ errorCode }">
      <VAlert :value="true" type="error" transition="scale-transition">
        <span v-text="form.$t(`errors.${errorCode}.alert.text`)" />
      </VAlert>
    </template>
  </KioskFormNotifications>
</template>

<script>
import { KIOSK_INSTALLATION_BRV_000006 } from './errors.js'

import KioskFormNotifications from '@/components/KioskFormNotifications'
import KioskDialogConfirm from '@/components/KioskDialogConfirm'

export default {
  name: 'KioskInstallFormNofications',
  components: {
    KioskFormNotifications,
    KioskDialogConfirm
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      errorCodes: {
        KIOSK_INSTALLATION_BRV_000006
      }
    }
  }
}
</script>
