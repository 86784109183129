export const API_ERROR_CODE_UNDEFINED = 'API_ERROR_CODE_UNDEFINED'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const NETWORK_OFFLINE = 'NETWORK_OFFLINE'
export const KIOSK_INSTALLATION_BRV_000001 = 'KIOSK_INSTALLATION_BRV_000001'
export const KIOSK_INSTALLATION_BRV_000002 = 'KIOSK_INSTALLATION_BRV_000002'
export const KIOSK_INSTALLATION_BRV_000003 = 'KIOSK_INSTALLATION_BRV_000003'
export const KIOSK_INSTALLATION_BRV_000004 = 'KIOSK_INSTALLATION_BRV_000004'
export const KIOSK_INSTALLATION_BRV_000005 = 'KIOSK_INSTALLATION_BRV_000005'
export const KIOSK_INSTALLATION_BRV_000006 = 'KIOSK_INSTALLATION_BRV_000006'
export const KIOSK_VERIFY_LICENSE_FROM_APP_TOKEN_BRV_000007 =
  'KIOSK_VERIFY_LICENSE_FROM_APP_TOKEN_BRV_000007'
